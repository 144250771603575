import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "./Home";
// import Questions from "./Questions";
import BrandsHome from "./Brands/BrandsHome";
import PdfViewer from "./PdfViewer";
import PrivacyPolicyPDF from "./assets/policies/Privacy Policy.pdf";
import RefundReturnPDF from "./assets/policies/Refund and Return Policy.pdf";
import ShippingPDF from "./assets/policies/Shipping and Delivery Policy.pdf";
import TermsConditionPDF from "./assets/policies/Terms of Use.pdf";
import GetStarted from "./pages/GetStarted";
import Support from "./Support";
import AppLinks from "./AppLinks";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />}>
          <Route index element={<Home />} />
        </Route>
        <Route path="/get-started" element={<GetStarted />} />
        <Route path="/brands" element={<BrandsHome />} />
        <Route path="/support" element={<Support />} />
        <Route
          path="/policies/terms-and-condition"
          element={<PdfViewer file={TermsConditionPDF} />}
        />
        <Route
          path="/policies/privacy-policy"
          element={<PdfViewer file={PrivacyPolicyPDF} />}
        />
        <Route
          path="/policies/refund-and-return"
          element={<PdfViewer file={RefundReturnPDF} />}
        />
        <Route
          path="/policies/shipping-and-delivery"
          element={<PdfViewer file={ShippingPDF} />}
        />
        {/* For any /app/* re route to google.com*/}
        <Route path="/app/*" element={<AppLinks />} />
      </Routes>
    </BrowserRouter>
  );
}


