// Page to redirect to google.com

import { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";

export default function AppLinks() {
    useEffect(() => {
        // window.location.href = "https://onelink.to/f2hxum";
        // If Desktop redirect to lynkd.app
        // If android redirect to https://play.google.com/store/apps/details?id=com.lynkd.socialcommerce
        // If ios redirect to https://apps.apple.com/us/app/lynkd/id6740008236

        // Get the user agent
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        // If Desktop redirect to lynkd.app
        if (/windows phone/i.test(userAgent) || /android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent)) {
            // If android redirect to https://play.google.com/store/apps/details?id=com.lynkd.socialcommerce
            if (/android/i.test(userAgent)) {
                window.location.href = "https://play.google.com/store/apps/details?id=com.lynkd.socialcommerce";
            } else {
                // If ios redirect to https://apps.apple.com/us/app/lynkd/id6740008236
                window.location.href = "https://apps.apple.com/us/app/lynkd/id6740008236";
            }
        } else {
            window.location.href = "https://lynkd.app";
        }

        // after 2 seconds redirect to lynkd.app
        setTimeout(() => {
            window.location.href = "https://lynkd.app";
        }, 2000);

        // window.location.href = "https://lynkd.app";
    }, []);

    return (
        <div>
            {/* <h1>App Links</h1>
            <button onClick={() => window.location.href = "https://onelink.to/f2hxum"}>Go to Google</button> */}
        </div>
    );
}